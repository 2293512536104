import React from 'react';

import { useProducts } from '@hooks/useProducts';

import Layout from '@components/Layouts/Layout';
import InformationBanner from '@components/Blocks/InformationBanner';
import ProductsBlock from '@components/Blocks/ProductsBlock';
import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';

export function Head() {
  return (
    <>
      <SEO
        title="Купить поздравительные открытки: красивые подарочные открытки с доставкой"
        description="Закажите уникальную поздравительную открытку для самых близких людей! Интересные дизайны от Периодики запомнятся и станут отличным дополнением к подарку!"
      />
      <YandexTableau />
    </>
  );
}

function ProductsPage() {
  const { postcardProducts } = useProducts();

  return (
    <Layout>
      <InformationBanner
        banner={{
          title: 'Подарочные открытки',
          description: ` <p>
          Добавьте к&nbsp;своему заказу трогательное вложение — открытку с&nbsp;теплой и&nbsp;душевной иллюстрацией. Она не&nbsp;только дополнит новогодний подарок, который вы&nbsp;приготовили для&nbsp;близкого, но и&nbsp;станет полноценной его&nbsp;частью благодаря запоминающимся рисункам и&nbsp;личному пожеланию, которое вы&nbsp;сможете написать на&nbsp;обратной стороне. 
          </p>
          `,
          image: 'postcard-banner.jpg',
          textColor: 'white',
        }}
        twoCols
      />
      <ProductsBlock products={postcardProducts} />
    </Layout>
  );
}

export default ProductsPage;
